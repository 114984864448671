<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'Service', title: 'Послуги' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_service)"
        :modal="show_service_dialog"
        @updateItemModal="serviceUpdateItemModal"
    />
    <MainModal
        :main="{ component: 'Regular', title: 'Нормативи' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_normative)"
        :modal="show_normative_dialog"
        @updateItemModal="normativeUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card-text class="pa-0">
          <v-tabs class="custom-tabs" color="success" v-model="tab">
            <v-tab class="text-left justify-start">
              Перелік послуг
            </v-tab>
            <v-tab class="text-left justify-start">
              Перелік нормативів
            </v-tab>

            <v-tab-item class="py-2">
              <v-row>
                <v-col cols="12">
                  <v-toolbar elevation="0" class="pa-0">
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="grey lighten-4 mr-2"
                               v-bind="attrs"
                               v-on="on"
                               @click="service_table_view = !service_table_view">
                          <v-icon>
                            {{ table_view ? 'mdi-view-list' : 'mdi-format-list-group' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Перемкнути вигляд списку</span>
                    </v-tooltip>
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="grey lighten-4"
                               v-bind="attrs"
                               v-on="on"
                               @click="$router.push({ name: 'ServiceCreate'})">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Створити нову послугу</span>
                    </v-tooltip>

                  </v-toolbar>
                </v-col>
                <template v-if="!service_table_view">
                  <v-col cols="12" sm="12" md="4" lg="3">
                    <v-card class="d-flex align-center justify-center fill-height" style="position: relative;"
                            @click.stop="$router.push({ name: 'ServiceCreate' })"
                            min-height="164"
                    >
                      <div style="position: absolute; right: 0; top: 4px">
                        <YouTubeButton
                            button-class="grey lighten-4 mr-2"
                            icon-color="error lighten-1"
                            button-small
                            :icon-size="22"
                            href="https://youtu.be/KtKz5C3tFNQ"
                        />
                      </div>
                      <div>
                        <div class="text-center">
                          <v-icon size="40" color="success">mdi-plus</v-icon>
                        </div>
                        <div class="title grey--text text--darken-1 text-center">
                          Створити нову послугу
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col
                      v-for="(item, idx) in services" :key="`service-${idx}`"
                      cols="12" sm="12" md="4" lg="3"
                  >
                    <v-card class="fill-height"
                            @click="onServiceItemClick(item)"
                            style="justify-content: space-between; display: flex; flex-direction: column;">
                      <v-list-item class="mb-2 pr-2 mt-1">
                        <v-list-item-icon class="mr-5">
                          <v-icon size="36"
                                  :color="getAccountIcon(item.icon, 'color')"
                          >
                            {{ getAccountIcon(item.icon, 'icon') }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title style="white-space: normal; max-height: 37px">{{
                              `${item.name} (${item.abbreviation ? item.abbreviation : ''})`
                            }}
                          </v-list-item-title>
                          <v-list-item-title class="mt-2">
                            <v-chip-group column>
                              <v-chip small label class="mr-1" color="grey lighten-3">
                                <v-icon small left>mdi-calculator</v-icon>
                                {{ calculateMethods[item.calculate_type] || 'не визначено' }}
                              </v-chip>
                              <v-chip small label>
                                <v-icon small left>mdi-gauge-full</v-icon>
                                {{ item.has_counter ? 'лічильник' : 'без лічильника' }}
                              </v-chip>
                              <v-chip small label color="error" v-if="item.one_time">
                                <v-icon small left dark>mdi-gauge-full</v-icon>
                                Одноразова
                              </v-chip>
                            </v-chip-group>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-card-text class="d-flex justify-space-between align-center py-2">
                        <div>
                          {{ item.create_date | formatDate }}
                        </div>
                        <div>
                          <v-chip small>
                            <v-icon small left>mdi-account-supervisor-outline</v-icon>
                            к-сть {{ item.flats || 0 }}
                          </v-chip>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12" md="12">
                    <v-card>
                      <v-card-text>
                        <v-data-table
                            :headers="servicesHeaders"
                            :items="services"
                            no-data-text="Не створено жодної послуги. Натистніть + для створення"
                            :footer-props="{
                              showFirstLastPage: true,
                              itemsPerPageText: 'Рядків на сторінку',
                              itemsPerPageOptions: [15, 25, 40, -1],

                            }"
                            class="custom-table custom-table-1"
                            @click:row="onServiceItemClick"
                        >
                          <template v-slot:item.icon="{ item }">
                            <v-icon size="26"
                                    :color="getAccountIcon(item.icon, 'color')"
                            >
                              {{ getAccountIcon(item.icon, 'icon') }}
                            </v-icon>
                          </template>
                          <template v-slot:item.calculate_type="{ item }">
                            <v-chip small label class="mr-1" color="grey lighten-3">
                              <v-icon small left>mdi-calculator</v-icon>
                              {{ calculateMethods[item.calculate_type] || 'не визначено' }}
                            </v-chip>
                          </template>
                          <template v-slot:item.has_counter="{ item }">
                            <v-chip small label>
                              <v-icon small left>mdi-gauge-full</v-icon>
                              {{ item.has_counter ? 'лічильник' : 'без лічильника' }}
                            </v-chip>
                          </template>
                          <template v-slot:item.name="{ item }">
                            {{ `${item.name} (${item.abbreviation ? item.abbreviation : ''})` }}
                          </template>
                          <template v-slot:item.one_time="{ item }">
                            <v-simple-checkbox color="success" :value="item.one_time"/>
                          </template>
                          <template v-slot:item.create_date="{ item }">
                            {{ item.create_date | formatDate }}
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="py-2">
              <v-row>
                <v-col cols="12">
                  <v-toolbar elevation="0" class="pa-0">
                    <div style="max-width: 360px; flex: 1">
                      <v-select label="Послуга" color="grey" filled
                                hide-details v-model="filter_service_id" :items="services_select"
                                clearable @change="onServiceChange"
                      />
                    </div>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="grey lighten-4 mr-2"
                               v-bind="attrs"
                               v-on="on"
                               @click="table_view = !table_view">
                          <v-icon>
                            {{ table_view ? 'mdi-view-list' : 'mdi-format-list-group' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Перемкнути вигляд списку</span>
                    </v-tooltip>
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="grey lighten-4"
                               v-bind="attrs"
                               v-on="on"
                               @click="$router.push({ name: 'NormativeCreate'})">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Створити новий норматив</span>
                    </v-tooltip>

                  </v-toolbar>
                </v-col>

                <template v-if="!table_view">
                  <v-col cols="12" sm="12" md="4" lg="3">
                    <v-card class="d-flex align-center justify-center fill-height"
                            @click.stop="$router.push({ name: 'NormativeCreate'})"
                            min-height="164"
                    >
                      <div>
                        <div style="position: absolute; right: 0; top: 4px">
                          <YouTubeButton
                              button-class="grey lighten-4 mr-2"
                              icon-color="error lighten-1"
                              button-small
                              :icon-size="22"
                              href="https://youtu.be/gU5RWMrFfe8"
                          />
                        </div>
                        <div class="text-center">
                          <v-icon size="40" color="success">mdi-plus</v-icon>
                        </div>
                        <div class="title grey--text text--darken-1 text-center">
                          Створити новий норматив
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col
                      v-for="(item, idx) in regulators" :key="`service-${idx}`"
                      cols="12" sm="12" md="4" lg="3"
                  >
                    <v-card class="fill-height"
                            @click="onNormativeItemClick(item)"
                            style="justify-content: space-between; display: flex; flex-direction: column;">
                      <v-card-text class="pb-2 pt-3" style="flex: 1">
                        <v-card-text class="col-text py-1 px-0">
                          <div>Назва: </div>
                          <div class="success--text text--darken-2">{{ item.name }}</div>
                        </v-card-text>
                        <v-card-text class="col-text py-1 px-0">
                          <div>Послуга: </div>
                          <div>{{ item.service_name }} {{item.eps_id ? ` (${item.eps_id})` : ''}}</div>
                        </v-card-text>
                        <v-card-text class="col-text py-1 px-0">
                          <div>Вид: </div>
                          <div>{{item.social_normative ? `Соціальний норматив` : 'Норматив для нарахування'}}</div>
                        </v-card-text>
                        <v-card-text class="col-text py-1 px-0">
                          <div>Значення: </div>
                          <div>{{ getRegularDetails(item) }}</div>
                        </v-card-text>
                      </v-card-text>
                      <v-card-text class="d-flex justify-space-between align-center py-2" style="flex: 0; border-top: 1px solid #e0e0e0">
                        <div>
                          {{ item.create_date | formatDate }}
                        </div>
                        <div>
                          <v-icon
                              size="26"
                              :color="getAccountIcon(item.icon, 'color')"
                          >
                            {{ getAccountIcon(item.icon, 'icon') }}
                          </v-icon>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12">
                    <v-card>
                      <v-card-text>
                        <v-data-table
                            :headers="normativeHeaders"
                            :items="regulators"
                            no-data-text="Не створено жодного нормативу. Натистніть + для створення"
                            :footer-props="{
                          showFirstLastPage: true,
                          itemsPerPageText: 'Рядків на сторінку',
                          itemsPerPageOptions: [15, 25, 40, -1],

                        }"
                            class="custom-table custom-table-1"
                            @click:row="onNormativeItemClick"
                        >

                          <template v-slot:item.service_name="{ item }">
                            <v-icon
                                size="24"
                                class="mr-2"
                                :color="getAccountIcon(item.icon, 'color')"
                            >
                              {{  getAccountIcon(item.icon, 'icon') }}
                            </v-icon>
                            <span>{{ item.service_name }}</span>
                          </template>
                          <template v-slot:item.social_normative="{ item }">
                            <v-simple-checkbox :value="item.social_normative" color="success" :ripple="false"/>
                          </template>
                          <template v-slot:item.description="{ item }">
                            {{getRegularDetails(item)}}
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {FETCH_REGULARS, FETCH_SERVICES} from "@/store/actions/services";
import {calculateMethods, getAccountIcon, getRegularDetails} from "@/utils/icons";
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";
import MainModal from "@/components/modal/MainModal";

export default {
  name: "ServiceView",
  components: {
    MainModal,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  computed: {
    ...mapGetters({
      services: 'getServices',
      services_select: 'getServicesSelectWithoutAll',
      regulators: 'getRegulars',
      legacy: 'isLegacy',
    })
  },
  data() {
    return {
      calculateMethods,
      tab: 0,
      table_view: false,
      normativeHeaders: [
        {text: '', value: 'hidden', width: '5px', sortable: false, hidden: true},
        {text: 'Послуга', value: 'service_name'},
        {text: 'Найменування', value: 'name'},
        {text: 'Соціальний', value: 'social_normative'},
        {text: 'Опис', value: 'description'},
      ],
      servicesHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Послуга', value: 'name'},
        {text: 'Тип нарахування', value: 'calculate_type'},
        {text: 'По лічильнику', value: 'has_counter'},
        {text: 'Одноразова', value: 'one_time'},
        {text: 'Дата створення', value: 'create_date'},
      ],
      normative_modal_id: 0,
      filter_service_id: 0,
      filter_social_normative: 0,
      service_item_modal_id: 0,
      service_table_view: false,
      selected_service: {},
      show_service_dialog: false,
      selected_normative: {},
      show_normative_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetchServices: FETCH_SERVICES,
      fetchIndicators: FETCH_FLAT_INDICATOR_TYPES,
      fetchRegulars: FETCH_REGULARS
    }),
    onServiceChange(payload) {
      this.fetchRegulars({legacy: this.legacy, service_id: payload})
    },
    getAccountIcon,
    getRegularDetails,
    serviceUpdateItemModal() {
      this.show_service_dialog = false
      this.selected_service = {}
    },
    onServiceItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.service_icon = local_payload.icon
      local_payload.calculate_method = local_payload.calculate_type
      this.selected_service = local_payload
      this.show_service_dialog = true
    },
    normativeUpdateItemModal() {
      this.show_normative_dialog = false
      this.selected_normative = {}
    },
    onNormativeItemClick(payload) {
      this.selected_normative = payload
      this.show_normative_dialog = true
    },
    saveTableViewToLocalStorage() {
      if (localStorage.getItem('services_view_table_mode') === null) {
        localStorage.setItem('services_view_table_mode', this.service_table_view)
      } else {
        localStorage.removeItem('services_view_table_mode')
        localStorage.setItem('services_view_table_mode', this.service_table_view)
      }

      if (localStorage.getItem('normative_view_table_mode') === null) {
        localStorage.setItem('normative_view_table_mode', this.table_view)
      } else {
        localStorage.removeItem('normative_view_table_mode')
        localStorage.setItem('normative_view_table_mode', this.table_view)
      }
    },
  },
  created() {
    const services_view_table_mode = localStorage.getItem('services_view_table_mode')
    const normative_view_table_mode = localStorage.getItem('normative_view_table_mode')
    if (services_view_table_mode !== null) {
      this.service_table_view = services_view_table_mode
    }
    if (normative_view_table_mode !== null) {
      this.table_view = normative_view_table_mode
    }
  },
  beforeDestroy() {
    this.saveTableViewToLocalStorage()
  },
  watch: {
    legacy: {
      immediate: true,
      handler(payload) {
        this.fetchServices()
            .then(response => {
              if (response) {
                this.fetchIndicators()
                this.fetchRegulars({legacy: payload})

                if (this.$route.query['tab']) {
                  this.tab = +this.$route.query['tab']
                }
              }
            })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.col-text {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;


  div:nth-child(1) {
    flex: 0 0 100px;
    color: #606060;
    font-weight: 500;
  }
  div:nth-child(2) {
    flex: 1;
  }
}
.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
}
</style>